import React, { useState } from "react";
import CountdownTimer from "./CountdownTimer";
import FormModal from "./FormModal";
import BackgroundVideo from "./BackgroundVideo";
import "./App.css"; // Import your custom styles
import { targetDate } from "./constants";

const App = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formType, setFormType] = useState("");
  const [isPlaying, setIsPlaying] = useState(true); // Manage video playback state

  const handleOpenModal = (type) => {
    setFormType(type);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const togglePlayback = () => {
    setIsPlaying(!isPlaying);
  };

  return (
    <div className="app-container">
      <BackgroundVideo isPlaying={isPlaying} togglePlayback={togglePlayback} />
      <CountdownTimer targetDate={targetDate} />
      <div className="content">
        <h1 className="heading">KARSHO</h1>
        {/* <p className="tagline">BY VIVID KONCEPTS</p> */}
        <h2 className="subheading">
          The community designed for true enthusiasts.
        </h2>
        <div className="buttons">
          <button onClick={() => handleOpenModal("upload")}>
            Pre-Upload Content
          </button>
          <button onClick={() => handleOpenModal("notify")}>
            Get Notified
          </button>
        </div>
      </div>
      {isModalOpen && (
        <FormModal formType={formType} onClose={handleCloseModal} />
      )}

      {/* <button onClick={togglePlayback} className="play-pause-button">
        {isPlaying ? "Pause Content" : "Play Content"}
      </button> */}
      {/* Footer */}
      <footer className="footer">
        <p>© {new Date().getFullYear()} KARSHO LLC</p>
      </footer>
    </div>
  );
};

export default App;
